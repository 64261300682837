import { render, staticRenderFns } from "./PortalModuleExternalCard.vue?vue&type=template&id=e06fe002&scoped=true&"
import script from "./PortalModuleExternalCard.vue?vue&type=script&lang=js&"
export * from "./PortalModuleExternalCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e06fe002",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\DevOpsAgents\\Agent-04\\_work\\128\\s\\KM.Dataarkiv.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e06fe002')) {
      api.createRecord('e06fe002', component.options)
    } else {
      api.reload('e06fe002', component.options)
    }
    module.hot.accept("./PortalModuleExternalCard.vue?vue&type=template&id=e06fe002&scoped=true&", function () {
      api.rerender('e06fe002', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Portal/Card/PortalModuleExternalCard.vue"
export default component.exports