import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=1d3772eb&"
import script from "./Home.vue?vue&type=script&lang=ts&"
export * from "./Home.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\DevOpsAgents\\Agent-04\\_work\\128\\s\\KM.Dataarkiv.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1d3772eb')) {
      api.createRecord('1d3772eb', component.options)
    } else {
      api.reload('1d3772eb', component.options)
    }
    module.hot.accept("./Home.vue?vue&type=template&id=1d3772eb&", function () {
      api.rerender('1d3772eb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Election/Home.vue"
export default component.exports